import React from 'react';
import { Row } from 'react-bootstrap';
import * as Styled from './titleWithImageStyles';
import AOS from 'aos';

const TitleWithImage = ({ sectionData }) => {
  React.useLayoutEffect(() => {
    AOS.init({
      disable: function () {
        const maxWidth = 767;
        return window.innerWidth < maxWidth;
      },
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <Styled.MainContainer>
        <Row className="g-0">
          {sectionData?.text && (
            <Styled.LeftCol xs={12} md={7} lg={6} xl={7}>
              <Styled.HeaderDiv>
                <Styled.Header
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-once="true"
                  data-aos-offset="5px"
                >
                  {sectionData?.text?.text}
                </Styled.Header>
              </Styled.HeaderDiv>
            </Styled.LeftCol>
          )}
          {sectionData?.image?.file?.url && (
            <Styled.RightCol
              $background={sectionData?.image?.file?.url}
              md={5}
              lg={6}
              xl={5}
              className="d-none d-md-block"
            ></Styled.RightCol>
          )}
        </Row>
      </Styled.MainContainer>
    </>
  );
};

export default TitleWithImage;
